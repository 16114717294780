import React from "react"
import { Helmet } from "react-helmet"
import MainLayout from "@/layouts/Main"
import Home from "@/components/Home"

const Page = () => {
  return (
    <MainLayout>
      <Helmet title="Cardano Launchpad" />
      <Home />
    </MainLayout>
  )
}

export default Page
